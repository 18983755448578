// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-index-jsx": () => import("./../../../src/pages/index/index.jsx" /* webpackChunkName: "component---src-pages-index-index-jsx" */),
  "component---src-pages-index-intro-jsx": () => import("./../../../src/pages/index/intro.jsx" /* webpackChunkName: "component---src-pages-index-intro-jsx" */),
  "component---src-pages-index-our-portfolio-jsx": () => import("./../../../src/pages/index/our-portfolio.jsx" /* webpackChunkName: "component---src-pages-index-our-portfolio-jsx" */)
}

